
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'mynum';
    
    this.auth_shift = false;
    this.auth_attendance = false;
  }

  get is_change() {
    return this.object.mynum !== this.old_object.mynum;
  }
}

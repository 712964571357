
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import * as util from '@/util';
import LItem from "@/components/l/Item.vue";
import FDate from "@/components/f/Date.vue";

@Component({
  components: {
    LItem,
    FDate,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'birth_date';
    this._need = true;
    
    this.auth_shift = false;
    this.auth_attendance = false;
  }

  get is_change() {
    return this.object.birth_date !== this.old_object.birth_date;
  }

  get default_year() {
    return Number(util.formatDate(new Date(), 'yyyy')) - 20;
  }
}

import { render, staticRenderFns } from "./Sex.vue?vue&type=template&id=c92efa3a&scoped=true&lang=pug"
import script from "./Sex.vue?vue&type=script&lang=ts"
export * from "./Sex.vue?vue&type=script&lang=ts"
import style0 from "./Sex.vue?vue&type=style&index=0&id=c92efa3a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c92efa3a",
  null
  
)

export default component.exports